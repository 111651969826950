'use strict';

var util = require('./util');

function getStockData() {
	$.ajax({
        type: 'GET',
        dataType: 'json',
        contentType: 'application/json',
        url: util.appendParamsToUrl(Urls.getStockTicker),
        success: function(stockTickerResponse) {
            var stockTickerArray = stockTickerResponse.split(',');
            var stockPriceRound = Number(stockTickerArray[2]).toFixed(2);
            $('.stock-exchange').text(stockTickerArray[0]);
            $('.stock-symbol').text(stockTickerArray[1]);
            $('.stock-last-trade').text('$' + stockPriceRound);
            $('.stock-ticker').removeClass('hidden');
        }
    });
}

function init() {

	getStockData();
	
	setInterval(function() {
		getStockData();
	}, 300000) // 5 min
}

exports.init = function() {
    init();
};
