function initializeEvents(type) {
    var isTouch = "ontouchstart" in window;

    // TODO safeguard against script injection

    var $assetsContainer = document.querySelector(".corp-assets-lp-cont.assets-" + type);
    var $assets = document.querySelector(".corp-assets-lp-cont.assets-" + type + " .corp-assets-lp");
    var $moreButton = document.querySelector(".corp-assets-lp-cont.assets-" + type + " .load-more-assets");

    function loadMore() {
        currrentPage++;
        var url = '/on/demandware.store/Sites-RalphLauren_Corporate-Site/default/Assets-List?page=' + currrentPage + '&folder=' + folder;


        $.ajax({
            url: url,
            type: 'GET'
        }).done(function(response) {
            if (((currrentPage + 1) * itemsPerPage) >= total) {
                $moreButton.style.display = 'none';
                $assetsContainer.removeEventListener("load_more_assets", loadMore, false);
            }
            $("<div class=\"assets-page-cont\"/>").html(response).appendTo($assets);
            //$assets.append(html)
            $('html, body').animate({
                scrollTop: $(".corp-assets-lp-cont.assets-" + type + " .assets-page-cont:last-of-type").offset().top - 150
            }, 600);

        }).fail(function() {
            console.log('could not load assets')
        });

    }

    if ($assetsContainer) {
        var currrentPage = 0;
        var folder = window['assetsDataContainer_' + type].folder;
        var itemsPerPage = window['assetsDataContainer_' + type].itemsPerPage;
        var total = window['assetsDataContainer_' + type].total;
        //
        if (total <= itemsPerPage) {
            $moreButton.style.display = 'none';
        } else {
            $assetsContainer.addEventListener("load_more_assets", loadMore, false);
        }
    }
}



exports.init = function() {
    initializeEvents('mediaAssets_images');
    initializeEvents('mediaAssets_logos');
};
