function offsetAnchor() {
    if (location.hash.length !== 0) {
        window.scrollTo(window.scrollX, window.scrollY - 250);
    }
}

function initializeEvents() {

    if ($('.page-citizenship-and-sustainability,    .page-citizenship-create-timeless-style,    .page-citizenship-champion-better-lives,    .page-citizenship-protect-the-environment').length > 0)
        $(document).on('click', 'a[href^="#"]', function(event) {
            // Click events are captured before hashchanges. Timeout
            // causes offsetAnchor to be called after the page jump.
            window.setTimeout(function() {
                offsetAnchor();
            }, 0);
        });
}



exports.init = function() {
    initializeEvents();
};
