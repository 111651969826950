// reference all <video/> for global control
var players = [];
// store latest user sound actions
// by default the main video will be muted, volume set at 50%
var mutePreference = true;
var volumePreference = 0.5;
// help setting the correct events to be listened to
var checkUseMobile = window.matchMedia("(max-width: 767px)");
var usingMobile = null;

// swap between mobile and desktop video sources
function swapMedia() {
    var useMobile = checkUseMobile.matches;
    if (usingMobile != useMobile) {

        var $vids = document.querySelectorAll(".corp-video video");
        $vids.forEach(function($video) {
            var poster = $video.dataset[(useMobile ? 'mob' : 'dsk') + 'Pstr'];

            if (poster && poster != '') {
                $video.setAttribute("poster", poster);
            }
            var video = $video.dataset[(useMobile ? 'mob' : 'dsk') + 'Vid'];
            if (video && video != '' && $video.src != video) {
                var pos = $video.currentTime;
                $video.src = video;
                $video.load();
                if ($video.autoplay) {
                    $video.play();
                } 
                $video.addEventListener("canplay", function() {
                	$video.parentNode.classList.add('can-play');
                    if (!$video.parentNode.classList.contains('is-playing')) {
                        window.requestAnimationFrame(function() {
                            $video.pause();
                            // try landing at the same time position
                            $video.currentTime = pos;
                        });

                    }
                }, { once: true });
            }
        });

        usingMobile = useMobile;
    }

}

function initializeEvents() {
    var isTouch = "ontouchstart" in window;
    // set the correct version depending on device
    swapMedia();

    var $vids = document.querySelectorAll(".corp-video");
    $vids.forEach(function($videoContainer) {

        var isLoopPlaying = true; // TODO dynamic
        var _isLoopPlaying;
        var $videoLoop = $videoContainer.querySelector('.video-loop');
        var $loopPlayToggle = $videoContainer.querySelector('.corp-playpause-button');
        //
        var isfullPlaying = false;
        var $videoFull = $videoContainer.querySelector('.video-full');

        function playFullVideo() {
            // all other videos should pause
            pauseOthers();

            $videoFull.parentNode.classList.remove('video-hidden');
            $videoFull.parentNode.classList.add('is-playing');
            $videoFull.muted = mutePreference;
            $videoFull.volume = volumePreference;
            var playPromise = $videoFull.play();
            if (playPromise !== undefined) {
            	  playPromise
            	  .then(function (e){
            		  //console.log('ok', e)
            	  })
            	  .catch(function (e){
            		  console.log('the video',$videoFull.currentSrc,'could not play', e)
            		  })
            }
            $videoFull.addEventListener('ended', function(e) {
                closeFullVideo();
            }, { once: true })

            
        }

        function closeFullVideo() {
            if ($videoLoop) {
                $videoLoop.parentNode.classList.remove('video-hidden');
                if (_isLoopPlaying) {
                    playLoop()
                }

            } else {

            }
            $videoFull.parentNode.classList.add('video-hidden');
            $videoFull.pause();
            //$videoFull.src = null;

            $videoFull.parentNode.classList.remove('is-playing');

        }

        function onPlayFullVideo() {
            _isLoopPlaying = isLoopPlaying;
            pauseLoop();
            $videoLoop.parentNode.classList.add('video-hidden');
            //

            playFullVideo();

        }

        function pauseLoop() {
            $videoLoop.pause();
            $videoLoop.parentNode.classList.remove('is-playing');
            isLoopPlaying = false;
        }

        function playLoop() {
            $videoLoop.play();
            $videoLoop.parentNode.classList.add('is-playing');
            isLoopPlaying = true;
        }

        function playPauseLoop() {
            if (isLoopPlaying) {
                pauseLoop();
            } else {
                playLoop();
            }

        }
        function pauseOthers(player){
        	players.forEach(function(p) {   
            	if (p && p != player && p.src != null) {
                    p.pause();
                }
            })
        }

        $videoContainer.addEventListener("play_full_video", onPlayFullVideo, false);
        if ($loopPlayToggle) {
            $loopPlayToggle.addEventListener(isTouch ? 'touchstart' : 'click', playPauseLoop, false);
        }
        if ($videoFull) {
            players.push($videoFull);
            $videoFull.parentNode.querySelector(".video-play:not(.video-play-shadow)").addEventListener(isTouch ? 'touchstart' : 'click', function() {
                playFullVideo();
            }, false);
            $videoFull.parentNode.querySelector(".close-video").addEventListener(isTouch ? 'touchstart' : 'click', closeFullVideo, false);
            $videoFull.addEventListener('volumechange', function(e) {
                mutePreference = e.target.muted;
                volumePreference = e.target.volume

            }, false)
            $videoFull.addEventListener('play', function(e) {
            	e.stopPropagation();
            	pauseOthers(e.target)

            }, false)
            //
            /*var video = $videoFull.dataset[(checkUseMobile.matches ? 'mob' : 'dsk') + 'Vid'];
            $videoFull.src = video;
            console.log($videoFull)*/
          
            
        }
    })

    checkUseMobile.addListener(swapMedia);

}



exports.init = function() {
    initializeEvents();
};
