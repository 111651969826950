'use strict';
require('./polyfills');
var slider = require('./slider'),
	bio = require('./bio'),
	video = require('./video'),
	news = require('./news'),
	assets = require('./assets'),
	stockTicker = require('./stock-ticker'),
	contact = require('./contact'),
    validator = require('./validator'),
    csr = require('./csr'),
    searchsuggest = require('./searchsuggest');

require('./search')();

window._corporate = {
    _DESKTOP: 'desktop',
    _TABLET: 'tablet',
    _MOBILE: 'mobile'
};

// if jQuery has not been loaded, load from google cdn ???
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

function setMediaType() {
    var media;
    if (window.matchMedia('screen and (min-width: 960px)').matches) {
        media = window._corporate._DESKTOP;
    } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 960px)').matches) {
        media = window._corporate._TABLET;
    } else if (window.matchMedia('screen and (max-width: 767px) and (orientation: landscape)').matches) {
        media = window._corporate._MOBILE;
    } else {
        media = window._corporate._MOBILE
    }
    window._corporate.mediaType = media;
}

function createEvent(eventType) {
    var evt;
    if (typeof(Event) === 'function') {
        evt = new Event(eventType,{'bubbles':true, 'cancelable':true});
    } else { // IE
        evt = document.createEvent('Event');
        evt.initEvent(eventType, true, true);
    }
    return evt;
}


var app = {
    init: function() {
    	
    	// Header
    	headerPaddingCalc();
    	setMediaType();

    	$('.mobile-menu-toggle').click(function() {
    		toggleMenu();
		});
    	
    	$('.mobile-menu-close').on('click', function () {
    		toggleMenu();
		});
    	
    	$('.sub-item-toggle').on('click', function () {
    		var subMenu = $(this).parents('.sub-nav').find('.sub-nav-list');
    		toggleSubNav(subMenu);
    	});
    	$('.sub-item-back').on('click', function() {
    		var subMenu = $(this).parents('.sub-nav').find('.sub-nav-list');
    		toggleSubNav(subMenu);
    	});
    	
    	$('.nav-item').hover(function () {
    		if($(this).hasClass('sub-nav')) {
    			var subNav = $(this).children('.sub-nav-list');
    			subNav.position({
    	        	my: 'bottom',
                    at: 'bottom',
                    of: subNav,
                    collision: 'flipfit'
    	        });
    		}
    	});
        $('.nav-item').on('keydown',function (e) {
            var key = e.which;
            if(key == 32)  {// the space key code
                e.preventDefault();
                if ($(this).hasClass('hover')) {
                    $(this).removeClass('hover');
                    $(this).attr('aria-expanded', false);
                } else {
                    $('.nav-item').removeClass('hover').attr('aria-expanded', false);
                    $(this).addClass('hover');
                    $(this).attr('aria-expanded', true);
                }
                //Trigger hover for keyboard accessibility
                return false;  
            }
        }); 
        	
    	$(window).resize( function (){
    		headerPaddingCalc();
    		showNav();
    		setMediaType();
    	});
    	
    	// Initialize Widgets
    	bio.init();
    	csr.init();
        video.init();
        stockTicker.init();
        news.init();
        assets.init();
        contact.init();
        validator.init();

    	// Slider
        if (typeof(window.Flickity) != 'undefined') {
            slider.init();
        }
        
        // Phone Links
        if ($('#main').find('.phone-link').length > 0 && $(window).width() > 960) {
        	$('.phone-link').removeAttr('href');
        }
        
        // Event Button
        var $els = document.querySelectorAll('.corp-event-button');
        $els.forEach(function ($el){
        	var evt = function(evt){ 
        		evt.preventDefault();
        		evt.currentTarget.dispatchEvent(createEvent(evt.currentTarget.dataset.event))
        	};
        	$el.addEventListener('touchend', evt, false);
        	$el.addEventListener('click', evt, false);
        })
        
        // Search
        var $searchContainer = $('.search-flyout');
        searchsuggest.init($searchContainer.find('.search-flyout-content'), Resources.SIMPLE_SEARCH);
        
    	// Accessibility
    	window.addEventListener('keydown', handleFirstTab);

    }
};

function toggleMenu() {
	$('#navigation').toggle('slide', {
        direction: 'left'
    }, 300);
	$('body').toggleClass('no-scroll');
	$('.mobile-menu-toggle').toggleClass('open');
	$('.sub-nav-list').removeClass('open');
	$('.sub-nav-list').prop('style', '');
	
	// Accessibility focus
	if ($('.mobile-menu-toggle').hasClass('open')) {
		$('.header-sticky').find('.nav-mobile-header a, .nav-list .nav-item a').first().focus();
	} else {
		$('.header-sticky').find('.mobile-menu-toggle').focus();
	}	
}

function toggleSubNav(subMenu) {
	subMenu.toggle('slide', {
        direction: 'left'
    }, 300);	
	subMenu.toggleClass('open');
	
	// Accessibility focus
	if ($('body').hasClass('user-is-tabbing')) {
		if (subMenu.hasClass('open')) {
			$('.header-sticky').find('.sub-nav-list.open ul').focus();
		} else {
			$('.header-sticky').find('.nav-list').focus();
		}
	}
}

function headerPaddingCalc() {
	var headerHeight = $('.header-sticky').outerHeight();
	$('#wrapper').css('padding-top', headerHeight);
}

function showNav() {
	if ($(window).width() > 960) {
		$('#navigation').prop('style', '');
		$('.sub-nav-list').prop('style', '');		
		$('.sub-nav-list').removeClass('open');		
	}
}

//Accessibility
function handleFirstTab(e) {
    if (e.keyCode === 9) {
        document.body.classList.add('user-is-tabbing');
    
        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
}
function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');
  
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
}

function setCookie(key, value, minutes) {
    var date = new Date();
    date.setTime(date + (minutes * 60 * 1000));

    document.cookie = key + '=' + encodeURIComponent(value) + '; expires=' + date.toGMTString() + '; path=/';
}

// initialize app
$(document).ready(function() {
    app.init();
});
