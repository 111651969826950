var util = require('./util');

function initializeEvents() {
    var isTouch = "ontouchstart" in window;

    // TODO safeguard against script injection

    var $newsContainer = document.querySelector(".corp-news-lp-cont");
    var $news = document.querySelector(".corp-news-lp");
    var $moreButton = document.querySelector(".load-more-news");
    var $yearDropDowns = document.querySelectorAll(".yearinput");
    var $categoryDropDown = document.querySelectorAll(".categoryinput");

    function loadMore(isYear) {
        currentPage++;
        var url = '/on/demandware.store/Sites-RalphLauren_Corporate-Site/default/News-List?page=' + currentPage + '&year=' + currentFilter + '&folder=' + currentFolder + '&count=' + itemsPerPage  + '&category=' + currentCategory;

        $.ajax({
            url: url,
            type: 'GET'
        }).done(function(response) {
        	if (isYear != true) {
        		//load more
        		var cleanResponse = $(response).find('.corp-widget')[0].innerHTML;
        		$('.corp-widget').append(cleanResponse);
        	} else {
        		//filter
        		$('<div class="news-page-cont">').html(response).fadeIn(500).appendTo($news);
        		$('html, body').animate({
                    scrollTop: $('.news-page-cont:first-of-type').offset().top - $('.header-sticky').height() + 'px'
                }, 600);
        	}
        	
        	$('.category-filter').each(function() {
        		if ($('.categoryinput').val() == $(this).text()) {        			
        			$(this).addClass('disabled');
        		}
        	});
            var total = window.newsDataContainer.total;
            $('.category-filter').bind('click', updateCategory);

        	if (((currentPage + 1) * itemsPerPage) >= total) {
                $moreButton.style.display = 'none';
                $newsContainer.removeEventListener('load_more_news', loadMore, false);
            } else {
            	if($moreButton.style.display == 'none'){
            		$moreButton.style.display = 'block';
                    $newsContainer.addEventListener('load_more_news', loadMore, false);
            	}            	
            }  
        }).fail(function() {
            console.log('could not load news')
        });

    }
    
    function filterNews() {    	
    	$('.news-page-cont').fadeOut(300, function() {
    		$(this).remove();
		});
    	currentPage = -1;
        loadMore(true);
    }
    function updateCategory(event) {
		if ($('.categoryinput').val() == $(this).text()) {
			return false;
		}
	    currentCategory = encodeURIComponent($(event.target).text());
	    $('.categoryinput').val($(event.target).text()).addClass('valid');
	    filterNews();
    }

    if ($newsContainer) {
        var currentPage = 0;
        var currentFolder = window.newsDataContainer.folder
        var currentFilter = window.newsDataContainer.year;
        var possibleYears = window.newsDataContainer.years;
        var urlParams = new URLSearchParams(window.location.search);
        var currentCategory = urlParams.get('category') || null;
        var categories = window.newsDataContainer.categories;
        var itemsPerPage = window.newsDataContainer.itemsPerPage;
        var total = window.newsDataContainer.total;

        $yearDropDowns.forEach(function($drop) {
            possibleYears.forEach(function(year) {
                var opt = new Option(year, year);
                opt.classList.add('select-option');
                $drop.options[$drop.options.length] = opt;

            });
            $drop.addEventListener('change', function() {
                if (this.value == 'select') {
                    $drop.classList.remove('valid');
                    currentFilter = null;
                } else {
                    $drop.classList.add('valid');
                    currentFilter = this.value;
                }
                filterNews();
            });
        });
        
        $categoryDropDown.forEach(function($drop) {
        	categories.forEach(function(cat) {
                var opt = new Option(cat, cat);
                opt.classList.add('select-option');
                $drop.options[$drop.options.length] = opt;

            });
            $drop.addEventListener('change', function() {
                if (this.value == 'select') {
                    $drop.classList.remove('valid');
                    currentCategory = null;
                } else {
                    $drop.classList.add('valid');
                    currentCategory = encodeURIComponent(this.value);
                }
                filterNews();
            });
        });
        
        if (currentCategory) {
        	var clearParam = util.removeParamFromURL(window.location.href, 'category');
        	if (clearParam.substr(-1) === '?') {
        		clearParam = clearParam.substring(0, clearParam.length-1);
        	}
    	    window.history.pushState({ path: clearParam }, '', clearParam);
    	    $('.categoryinput').val(currentCategory).addClass('valid');
        }
        
        $('.category-filter').click(function(e) {
        	updateCategory(e);
        });
        
        if (total <= itemsPerPage) {
            $moreButton.style.display = 'none';
        } else {
            $newsContainer.addEventListener("load_more_news", loadMore, false);
        }
    }
}



exports.init = function() {
    initializeEvents();
};
