var evt_type = ("ontouchstart" in window) ? 'touchstart' : 'click';

var setEvents = function() {
    if (evt_type === 'touchstart') {
        $('.corp-bio-reg, .corp-bio-vip').addClass('touch-ok');
    } else {
        $('.corp-bio-reg, .corp-bio-vip').removeClass('touch-ok');
    }

    if (window.matchMedia("screen and (max-width: 767px)").matches) {
        $('.corp-bio')
            .off('mouseenter')
            .off('mouseleave');
        $('.corp-bio-front .more-less-toggle')
            .off('touchstart')
        $('.corp-bio-back .more-less-toggle')
            .off('touchstart')
        //
        $('.more-less-toggle')
            .on('touchstart click', function(e) {
                console.log('XXX', window._corporate.mediaType)
                if (window._corporate.mediaType == window._corporate._TABLET) {
                    $(this).closest('.corp-bio-reg-tile').toggleClass('is-flipped');
                } else {
                    $(this).closest('.copy-card0').toggleClass('bio-expanded');
                }
            })


    } else {
        $('.more-less-toggle')
            .off('touchstart')

        //
        $('.corp-bio')
            .on('mouseenter', function() {
                $(this).find('.corp-bio-reg-tile').addClass('is-flipped');
            })
            .on('mouseleave', function(e) {
                $(this).find('.corp-bio-reg-tile').removeClass('is-flipped');
            });
        $('.corp-bio-front .more-less-toggle')
            .on('touchstart', function(e) {
                $(this).closest('.corp-bio-reg-tile').addClass('is-flipped');
            })
        $('.corp-bio-back .more-less-toggle')
            .on('touchstart', function(e) {
                console.log('hit')
                $(this).closest('.corp-bio-reg-tile').removeClass('is-flipped');
            })
    }
}




function initializeEvents() {


    var t2 = false;
    window.addEventListener('resize', function() {
        if (!t2) {
            window.requestAnimationFrame(setEvents);
            t2 = true;
        }
        t2 = false;
    });
    var t3 = false;
    window.addEventListener('orientationchange',function() {
        if (!t3) {
            window.requestAnimationFrame(setEvents);
            t3 = true;
        }
        t3 = false;
    });
    setEvents()
}



exports.init = function() {
    initializeEvents();
};
