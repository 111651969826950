'use strict';

var naPhone = /^\(?([2-9][0-8][0-9])\)?[\-\. ]?([2-9][0-9]{2})[\-\. ]?([0-9]{4})(\s*x[0-9]+)?$/;
var regex = {
    phone: {
        us: naPhone,
        other: /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/
    },
    postal: {
        us: /^\d{5}(-\d{4})?$/,
        ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
        fr: /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
        it: /^([0-9]){5}$/,
        jp: /^([0-9]){3}[-]([0-9]){4}$/,
        cn: /^([0-9]){6}$/,
        gb: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    },
    notCC: /^(?!(([0-9 -]){13,19})).*$/
};
// global form validator settings
var settings = {
    errorClass: 'error',
    errorElement: 'span',
    onkeyup:  false,
    onfocusout: function (element) {
        var $this = this;
        var currentForm = this.currentForm;

        if (!this.checkable(element)) {
            this.element(element);
        }
        if ($(element).closest('.cc-number').length > 0 && $(element).closest('.cc-number').find('.form-caption').text() == 'Invalid Credit Card Number. Please check number and re-enter.' && !$(element).closest('.cc-number').find('.form-caption').hasClass('cc-error')) {
            $(element).removeClass('cc-error');
            $(element).closest('.cc-number').find('.form-caption').text('');
        }
        
        if ($(element).closest('.user-cred-details').length > 0) {
    		if ($(element).closest('.user-cred-details').find('.cc-error').length > 1 || $(element).closest('.user-cred-details').find('.cvn span.error').css('display') == 'block') {
        		$(element).closest('form').find('#continue_review').prop('disabled', true);
        		$(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
        	}
            if ($(element).closest('.user-cred-details').find('.expired').length > 0) {
                $(element).closest('form').find('#continue_review').prop('disabled', true);
                $(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
            }
    		if (($(element).closest('.user-cred-details').find('.expired').length == 0) && $(element).closest('.input-wrapper').hasClass('cc-number') && $(element).closest('.user-cred-details').find('.cc-error').length == 0 && $(element).closest('.user-cred-details').find('.cvn span.error').css('display') != 'block' && $(element).closest('form').valid()) {
        		$(element).closest('form').find('#continue_review').prop('disabled', false);
        		$(element).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',false);  		
    		}
        }
        
        $.each(currentForm, function (key, value) {
            if ($(value).val()) {
                if (!$this.checkable(value)) {
                    $this.element(value);
                }
            }
        });
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        }
    },
    onclick: function (element) {
        if (!this.checkable(element)) {
            this.element(element);
        }
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        }
    },
    unhighlight: function (element, errorClass, validClass) {
        if (!$(element).hasClass('required') && $(element).val() == '') {
            $(element).removeClass(errorClass).removeClass(validClass);
        } else {
            $(element).removeClass(errorClass).addClass(validClass);
        }
        if ($('.gift-Message-error').length > 0) {
        	$('button[name$="chkoutButton"]').prop("disabled", true);
        	$('button[name$="_shippingAddress_save"]').prop("disabled", true);
        } 
    }
};

/**
 * @function
 * @description Validates a given phone number against the countries phone regex
 * @param {String} value The phone number which will be validated
 * @param {String} el The input field
 */
var validatePhone = function (value, el) {
    var isOptional = this.optional(el);
    var isValid = false;
    var isValidPhone = false;
    var phoneInput = $('input[name$=_phone]');
    var isValidPhoneIntl = phoneInput.intlTelInput('isValidNumber');
    // value = value.replace(/\+/g, '');

    for (var phRegex in regex.phone) {
        var country = regex.phone[phRegex];
        if (country.test(value) && value !=null && value.match(/\d/g).length >= 10) {
            isValidPhone = true;
            continue;
        }
    }

    if (isValidPhone === true || isValidPhoneIntl === true) {
        isValid = true;
        phoneInput.val(value);
    }

    return isOptional || isValid;
};

/**
 * @function
 * @description Validates that a credit card owner is not a Credit card number
 * @param {String} value The owner field which will be validated
 * @param {String} el The input field
 */
var validateOwner = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[a-zA-Z0-9\s]+$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};

/**
 * Add card CVN validation method to jQuery validation plugin.
 */
var cardcvnValidation = function (value, el) {
    value = value.replace(/\s+/g, '');
    $('.cvn .error-message').hide();
    if (value.length<3 ||value.length>4 ){
        return false;
    }
    var cardType = $(el).closest('form').find('.user-cred-details').find('[name$="_creditCard_type"]').val();
    var cardNumber = $(el).closest('form').find('.user-cred-details').find('.cc-number input').val();
    if ((cardNumber != '' && cardType != null && cardType != 'Amex' && value.length > 3) || (cardNumber != '' && cardType != null && cardType == 'Amex' && value.length == 3)) {
        $(el).closest('form').find('#continue_review').prop('disabled', true);
        $(el).closest('#container-wrap').find('.mini-summary-btn').prop('disabled',true);
        return false;   
    }
    
    return this.optional(el) || value.match(/^[0-9,*]*$/);
};

/**
 * Add card exp date validation
 */
var cardexpdateValidation = function (value, el) {
    var isValid = false;
	var expDate = $(el).closest('form').find('[name$="_expirationdate_date"]').val();
	var expMonth = expDate.split("/")[0];
	var expYear =  expDate.split("/")[1];
    var currentYear = parseInt(new Date().getFullYear().toString().substr(2,2));
    var currentMonth = parseInt(new Date().getMonth()+1);

    if ((expYear == currentYear && expMonth < currentMonth) || (expYear < currentYear)) {
		isValid = false;
	} else if ((value.length == 5) && ((expYear > currentYear) || (expYear == currentYear && expMonth >= currentMonth))) {
		isValid = true;
    }
    return isValid;
};


var ProfanityCheck = function(value, el) {
    var profanityWords = SitePreferences.PROFANITY_WORDS;
    var profan = SitePreferences.PROFANITY_WORDS;
    if (profanityWords == undefined || profanityWords == 'null' || profanityWords == '' || value == '') {
        if ($(el).closest('#GiftCertificateForm').length > 0) {
            if ($(el).closest('#GiftCertificateForm').find('.Profanity').closest('.field-wrapper').find('span').length == 0 || !($(el).closest('#GiftCertificateForm').find('.Profanity').hasClass('error'))) {
                $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', false);
            }
        }
        return true;
    }
    profanityWords = profanityWords.split('|');
    var valuecheck = value.toLowerCase().split(' ');
    for (var entval in valuecheck) {
        var profanArray = [];
        var entstr = valuecheck[entval].trim();
        for (var i = 0; i < profanityWords.length; i++) {
            var valData = profanityWords[i];
            if (entstr == valData) {
                if ($(el).closest('#GiftCertificateForm').length > 0) {
                    $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', true);
                }
                return false;
            }
            if (profan.indexOf(entstr) >= 0) {
                var index1 = profan.indexOf(entstr);
                var indexlast = profan.lastIndexOf('|', index1);
                var index2 = profan.indexOf('|', index1);
                var profanity = profan.substring(indexlast + 1, index2);
                var sentence = '';

                if (profan.split(' ').length > 1) {
                    var loopindx = Number(entval) + profanity.split(' ').length;
                    for (var j = Number(entval); j < loopindx; j++) {
                        var check = valuecheck[j];
                        if (check != undefined && check != '') {
                            if (sentence.length == 0) {
                                sentence = check.trim();
                            } else {
                                sentence = sentence + ' ' + check.trim();
                            }
                        }
                        if (check == '') {
                            loopindx++;
                        }
                    }
                    if (valData == sentence) {
                        if ($(el).closest('#GiftCertificateForm').length > 0) {
                            $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', true);
                        }
                        return false;
                    }
                }
            }
            if (valData.split(' ').length > 1) {
                var profanword = valData.split(' ')[0];
                if (profanword == entstr) {
                    profanArray.push(valData);
                }
            }
        }
        if (profanArray.length > 0) {
            for (var k = 0; k < profanArray.length; k++) {
                var sentence1 = '';
                var profanityword = profanArray[k];
                var loopindx = Number(entval) + profanityword.split(' ').length;
                for (var l = Number(entval); l < loopindx; l++) {
                    var check = valuecheck[l];
                    if (check != undefined && check != '') {
                        if (sentence1.length == 0) {
                            sentence1 = check.trim();
                        } else {
                            sentence1 = sentence1 + ' ' + check.trim();
                        }
                    }
                    if (check == '') {
                        loopindx++;
                    }
                }
                if (profanityword == sentence1) {
                    if ($(el).closest('#GiftCertificateForm').length > 0) {
                        $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', true);
                    }
                    return false;
                }

            }
        }
    }
    if ($(el).closest('#GiftCertificateForm').length > 0) {
        if ($(el).closest('#GiftCertificateForm').find('.Profanity').closest('.field-wrapper').find('span').length == 0 || !($(el).closest('#GiftCertificateForm').find('.Profanity').hasClass('error'))) {
            $(el).closest('#GiftCertificateForm').find(':input[type="submit"]').prop('disabled', false);
        }
    }
    return true;
};
/**
 * Add phone validation method to jQuery validation plugin.
 * Text fields must have 'phone' css class to be validated as phone
 */
$.validator.addMethod('phone', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('mobile', validatePhone, Resources.VALIDATE_PHONE);
$.validator.addMethod('Profanity', ProfanityCheck, Resources.PROFANITY_INCORRECTCHARACTERSS);
/**
 * Add CCOwner validation method to jQuery validation plugin.
 * Text fields must have 'owner' css class to be validated as not a credit card
 */
$.validator.addMethod('owner', validateOwner, Resources.VALIDATE_INCORRECTCHARACTERSS);

/**
 * Add credit card  validation method to jQuery validation plugin.
 * Text fields must have 'cvn_number' css class to be validated as not a credit card
 */
$.validator.addMethod('cvn_number', cardcvnValidation, Resources.CREDIT_CVN_VALIDATION);
$.validator.addMethod('exp_date', cardexpdateValidation, Resources.CREDIT_EXPDT_VALIDATION);
/**
 * Add gift cert amount validation method to jQuery validation plugin.
 * Text fields must have 'gift-cert-amont' css class to be validated
 */
$.validator.addMethod('gift-cert-amount', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = (!isNaN(value)) && (parseFloat(value) >= 5) && (parseFloat(value) <= 1000);
    return isOptional || isValid;
}, Resources.GIFT_CERT_AMOUNT_INVALID);

/**
 * Add positive number validation method to jQuery validation plugin.
 * Text fields must have 'positivenumber' css class to be validated as positivenumber
 */
$.validator.addMethod('positivenumber', function (value) {
    if ($.trim(value).length === 0) {
        return true;
    }
    return (!isNaN(value) && Number(value) >= 0);
}, ''); // '' should be replaced with error message if needed

$.validator.addMethod('message1', function (value, el) {
    var isOptional = this.optional(el);
    var isValid = false;

    if ($.trim(value).length === 0) {
        isValid = false;
    } else if ($.trim(value).length > 0) {
        isValid = true;
    }
    return isOptional || isValid;
}, Resources.VALIDATE_REQUIRED);

/*Start JIRA PREV-77 : Zip code validation is not happening with respect to the State/Country.*/
// function validateZip(value, el) {
//     var country = $(el).closest('form').find('.country');
//     if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
//         return true;
//     }
//     var isOptional = this.optional(el);
//     var isValid = regex.postal[country.val().toLowerCase()].test($.trim(value));
//     return isOptional || isValid;
// }
// $.validator.addMethod('postal', validateZip, Resources.INVALID_ZIP);
/*End JIRA PREV-77*/

function validateZip(value, el) {
    var isValid = false;
    var country = $(el).closest('form').find('.country').val();
    var isOptional = this.optional(el);
    var zipRegEx = /^\d{5}(-\d{4})?$/;
    var isStoreLocatorZip = $(el).attr('id') === 'dwfrm_storelocator_postalCode' ? true : false;
    value = $.trim(value);
    $(el).val(value);

    if (country === undefined && isStoreLocatorZip !== true) {
        //zipRegEx = /^\d{4,5}(-\d{4})?$/;
        return true;
    }
    if (country != 'US') {
        //zipRegEx = /^[0-9]|([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/;
        return true;
    }

    isValid = zipRegEx.test(value);

    return isOptional || isValid;
}
$.validator.addMethod('postal', validateZip, Resources.INVALID_POSTAL);

/*Start JIRA PREV-678 : Email and confirm email fields validation in gift certificate page not as expected.*/
var validateEmail = function (value, el) {
    value = $.trim(value);
    $(el).val(value);
    var isOptional = this.optional(el);
    var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = emailRegEx.test(value);
    return isOptional || isValid;
};


$.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);
/*End JIRA PREV-678*/

$.validator.addMethod('countryValid', function (value, el) {
	$(el).val(value);
    var isOptional = this.optional(el),
    	isValid = true;
    if($(el).hasClass("paypal-error-field")) {
    	isValid = false;
    }
    return isOptional || isValid;
}, function(params, el) {
	var thsParent = $(el).closest(".input-wrapper");
	var existingMsg = "";
	if(thsParent.find(".form-caption.error-message").text().length > 0) {
		existingMsg = thsParent.find(".form-caption.error-message").text();
	} else {
		existingMsg = thsParent.find("span.error").text();
	}
	return existingMsg;
});


var validateFirstName = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[a-zA-Z\s\'\-]+$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};
var getAmount = function (str) {
	var isValid = str.replace(/\d((\.|,)\d{2}$)*/g, '') === '';
	if (isValid){
		return parseFloat(str.replace(/ |,/g, function ($0){
			return $0 == ',' ? '.' : '';
		}))
	}
	return null
}

var validateRange = function (val, min, max) {
    return (val >= min && val <= max)
};

var validateDonationAmount = function (value, el) {	
	$('.pdpForm').find('.pdp-messaging .pdp-error-msg').text('');
   $(el).val(value);
   var isOptional = this.optional(el);
   var amount = getAmount(value);
   var isValid = validateRange(amount, 1, 10000);
   $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
   return isOptional || isValid;
};
var validateDonationCheckout = function (value, el) {	
	$('.pdpForm').find('.pdp-messaging .pdp-error-msg').text('');
   $(el).val(value);
   var isOptional = this.optional(el);
   var amount = getAmount(value);
   var isValid = isNaN(amount) || validateRange(amount, 1, 10000);
   $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
   return isOptional || isValid;
};
var validateGiftcertAmount = function (value, el) {	
	 $(el).val(value);
    var isOptional = this.optional(el);
    var amount = getAmount(value);
    var isValid = validateRange(amount, 5, 1000);
    $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
    return isOptional || isValid;
};
var validateAmount = function (value, el) {	
    $(el).val(value);
    var isOptional = this.optional(el);
    var amount = getAmount(value);
    var isValid = !isNaN(amount) && parseInt(amount) === amount;
    $(el).closest('form').find(':input[type="submit"]').prop('disabled', !isValid);
    return isOptional || isValid;
};

var validateLastName = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var nameRegEx = /^[^'][a-zA-Z0-9\'\-\s]*[^']$/;
    var isValid = nameRegEx.test(value);
    return isOptional || isValid;
};
var validateLatin = function(value) {
    var regXLatin = /[^\u0020-\u007F\u00A0-\u024F\u1E00-\u1EFF]/g;  
    var isValid = !(regXLatin.test(value));
    return isValid;
};

$.validator.addMethod('latincheck', validateLatin, Resources.VALIDATE_VALIDCHARACTERS);
$.validator.addMethod('firstName', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastName', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('firstname', validateFirstName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('lastname', validateLastName, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('amount', validateAmount, Resources.VALIDATE_INCORRECTCHARACTERSS);
$.validator.addMethod('giftcertAmountRange', validateGiftcertAmount, Resources.GIFT_CERT_AMOUNT_INVALID);
$.validator.addMethod('donationAmountRange', validateDonationAmount, Resources.DONATION_AMOUNT_INVALID);
$.validator.addMethod('donationAmountCheckout', validateDonationCheckout, Resources.DONATION_AMOUNT_INVALID);

var validateStoreNumber = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var numberRegEx = /^\d{1,5}$/;
    var isValid = numberRegEx.test(value);
    return isOptional || isValid;
}

var validateAccntNumber = function (value, el) {
    $(el).val(value);
    var isOptional = this.optional(el);
    var numberRegEx = /^\d{1,6}$/;
    var isValid = numberRegEx.test(value);
    return isOptional || isValid;
}

$.validator.addMethod('storeNumber', validateStoreNumber, Resources.VALIDATE_NUMBER);
$.validator.addMethod('employeeNumber', validateAccntNumber, Resources.VALIDATE_NUMBER);

$.extend($.validator.messages, {
    required: Resources.VALIDATE_REQUIRED,
    remote: Resources.VALIDATE_REMOTE,
    email: Resources.VALIDATE_EMAIL,
    url: Resources.VALIDATE_URL,
    date: Resources.VALIDATE_DATE,
    dateISO: Resources.VALIDATE_DATEISO,
    number: Resources.VALIDATE_NUMBER,
    digits: Resources.VALIDATE_DIGITS,
    creditcard: Resources.VALIDATE_CREDITCARD,
    equalTo: Resources.VALIDATE_EQUALTO,
    maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
    minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
    rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
    range: $.validator.format(Resources.VALIDATE_RANGE),
    max: $.validator.format(Resources.VALIDATE_MAX),
    min: $.validator.format(Resources.VALIDATE_MIN)
});

var validator = {
    regex: regex,
    settings: settings,
    init: function () {
        var self = this;
        $('form:not(.suppress)').each(function () {
            $(this).validate(self.settings);
        });
    },
    initForm: function (f) {
        $(f).validate(this.settings);
    }
};

module.exports = validator;
