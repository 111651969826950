function initSlider($elm, sliderConfig) {

    if (true || $elm.classList.contains("corp- ")) {
        sliderConfig = {
            imagesLoaded: true,
            wrapAround: true,
            freeScroll: true,
            groupCells: '80%'
        };
    } else if (!sliderConfig) {
        sliderConfig = {
            imagesLoaded: true,
            wrapAround: true,
            setGallerySize: true,
        };
    }
    //console.log("initSlider", $elm, sliderConfig);
    var flkty = new Flickity($elm, sliderConfig);

    //$elm.dispatchEvent('SLIDER_LOADED');

    flkty.on('select', function() {
        /*var $activeSlide = $elm.querySelectorAll(".rlc-slide")[flkty.selectedIndex];

        if (!$activeSlide.classList.contains("rlc-isvisible")) {
        	$activeSlide
        		.classList.add("rlc-isvisible");

        	$activeSlide
        		.dispatchEvent(ENTER_VIEWPORT);

        	// FIRE LEAVE VIEWPORT FOR SIBLINGS
        	let $siblings = utils.getSiblings($activeSlide);
        	utils.fireElementsEvent( $siblings, LEAVE_VIEWPORT );

        	// TRIGGER EVENT ON ALL LAZYLOAD SUB ELEMENTS
        	utils.fireElementsEvent( $activeSlide.querySelectorAll(".rlc-block.rlc-lazyLoad"), ENTER_VIEWPORT );

        }*/
    });


    // var slider = new Flickity($elm[0]);
    // ADD CLASS FOR DRAGGING SLIDER
    flkty.on('dragStart', function() {
        // Fix for iOS 11.3, 11.4, 12.0
        document.ontouchmove = function(e) {
            e.preventDefault();
        }

        $elm.classList.add("isDragging");
    }).on('dragEnd', function() {
        // Fix for iOS 11.3, 11.4, 12.0
        document.ontouchmove = function(e) {
            return true;
        }

        $elm.classList.remove("isDragging");
    });

    $elm.flickity = flkty;


}

function onImagesReady($currSlider, $firstSlideImg, sliderConfig) {
     //console.log("onImagesReady?", (typeof($firstSlideImg) == 'undefined' || ($firstSlideImg.src && $firstSlideImg.complete)));
    if (typeof($firstSlideImg) == 'undefined' || ($firstSlideImg.src && $firstSlideImg.complete)) {
        initSlider($currSlider, sliderConfig);
    } else {
        $firstSlideImg.addEventListener("load", function() {
            if (typeof($currSlider.flickity) != "undefined") {
                $currSlider.flickity.reloadCells();
            } else {
                initSlider($currSlider, sliderConfig);
            }
        });
    }
}

function initializeEvents() {
    let sliders = document.getElementsByClassName("corp-slider");
    //console.log('sliders', sliders.length)

    for (let key in sliders) {
        // skip loop if the property is from prototype
        if (!sliders.hasOwnProperty(key)) continue;

        let $currSlider = sliders[key];

        let slideTitle = "";

        if ($currSlider.classList.contains("corp-twovis")) {
            // console.log("CAT SLIDER");
            $currSlider.setAttribute("role", "navigation");
            $currSlider.setAttribute("aria-label", "category navigation");
            slideTitle = "item ";
        } else if ($currSlider.attributes["aria-label"] && $currSlider.attributes.role == "navigation") {
            // console.log("NAVIGATION");
            if (!$currSlider.attributes["aria-label"]) {
                $currSlider.setAttribute("aria-label", "navigation");
            }
            slideTitle = "item ";
        } else {
            // console.log("CAROUSEL");
            $currSlider.setAttribute("role", "carousel");
            $currSlider.setAttribute("aria-label", "carousel");
            slideTitle = "slide ";
        }

        var slideQty = $currSlider.querySelectorAll('.corp-slide').length;
        $currSlider.querySelectorAll('.corp-slide').forEach(function($this, index) {
            // var $this = jQuery(this);
            $this.setAttribute("tabindex", "0")
            $this.setAttribute("aria-label", slideTitle + (index + 1))
            $this.setAttribute("aria-posinset", index + 1)
            $this.setAttribute("aria-setsize", slideQty);
        });

        if (typeof($currSlider.flickityGUID) == "undefined") {
            var sliderConfig;
            var $firstSlideImg = $currSlider.querySelectorAll(".corp-slide")[0].querySelectorAll(".corp-image")[0];


            /*if ($currSlider.classList.contains("corp-block") || utils.findAncestor($currSlider, "corp-block")) {
                // $currSlider.on("ENTER_VIEWPORT", function () {
                $currSlider.addEventListener("ENTER_VIEWPORT", function() {
                    // console.log("SLIDER ENTER_VIEWPORT");
                    // prevent bubbling of lazyLoadImages from slides
                    if (!$currSlider.classList.contains("flickity-enabled")) {
                        onImagesReady($currSlider, $firstSlideImg, sliderConfig);
                    }
                }, "once");

                // TODO - ADD FLICKITY SCROLL EVENT FOR LAZYLOADING SLIDE IMAGES
            } else {*/
            onImagesReady($currSlider, $firstSlideImg, sliderConfig);
            /*}*/


        }

    }

}



exports.init = function() {
    initializeEvents();
};
